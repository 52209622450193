"use strict";
import { gsap, Power3, Power0 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { EaselPlugin } from "gsap/EaselPlugin";
import { spanWrapText } from "./_spanWrapText";

export { gsap };
export { ScrollTrigger };
export { EaselPlugin };
export function init() {
  gsap.registerPlugin(EaselPlugin, ScrollTrigger);
}
// gsap.registerPlugin(EaselPlugin, ScrollTrigger);
// ランダムなdelayを付けたいときに
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
}

// sectioning type
// - - - - - - - - - - - - - - - - -

export const riseUpTextSet = {
  duration: 6,
  display: "inline-block",
  overflow: "hidden",
  opacity: 0,
  y: 100,
  ease: "power3",
};
export const riseUpTextTo = {
  opacity: 1,
  y: 0,
  stagger: 0.06,
};
export function riseUpText() {
  document.querySelectorAll(".anim-riseup-text").forEach((item) => {
    spanWrapText(item);
    const child = item.getElementsByTagName("span");
    gsap.set(child, riseUpTextSet);
    gsap.to(child, {
      ...riseUpTextTo,
      scrollTrigger: {
        trigger: item,
        start: "top 75%",
      },
    });
  });
}
// sectioning letter
// - - - - - - - - - - - - - - - - -
export const fadeTextSet = {
  duration: 6,
  display: "inline-block",
  overflow: "hidden",
  opacity: 0,
  x: 50,
  ease: "power3",
};
export const fadeTextTo = {
  opacity: 1,
  x: 0,
  stagger: 0.06,
};
export function fadeText() {
  gsap.utils.toArray(".anim-fade-text").forEach((item) => {
    spanWrapText(item);
    const child = item.getElementsByTagName("span");
    gsap.set(child, fadeTextSet);
    gsap.to(child, {
      ...fadeTextTo,
      scrollTrigger: {
        trigger: item,
        start: "top 75%",
      },
    });
  });
}
export function stepLetter() {
  const stepLetter = document.querySelectorAll(".js-step-text");
  stepLetter.forEach((item, index) => {
    gsap.to(item, {
      scrollTrigger: {
        trigger: item,
        start: "top 85%",
        duration: 1,
        x: 0,
        opacity: 1,
        ease: "power2.out",
        stagger: {
          from: "start",
          amount: 1,
        },
      },
    });
  });
}
export function animTrigger() {
  // inview的アニメトリガー
  const animTarget = document.querySelectorAll(".animItem");
  animTarget.forEach((item, index) => {
    gsap.to(item, {
      scrollTrigger: {
        // scroller: ".container__main",
        trigger: item,
        start: "top 85%",
        once: true,
        // markers: true,
        toggleClass: {
          targets: item,
          className: "is-visible",
        },
        stagger: 0.3,
      },
    });
  });
}
