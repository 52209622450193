"use strict";

export function spanWrapText(target) {
  // const nodes = [...target.childNodes];

  // let spanWrapText = "";

  // nodes.forEach((node) => {
  //   if (node.nodeType == 3) {
  //     //テキストの場合
  //     const text = node.textContent.replace(/\r?\n/g, ""); //テキストから改行コード削除
  //     //spanで囲んで連結
  //     spanWrapText =
  //       spanWrapText +
  //       text.split("").reduce((acc, v) => {
  //         return acc + `<span>${v}</span>`;
  //       }, "");
  //   } else {
  //     //テキスト以外
  //     //<br>などテキスト以外の要素をそのまま連結
  //     spanWrapText = spanWrapText + node.outerHTML;
  //   }
  // });

  // target.innerHTML = spanWrapText;
  const spanWrapText = (target) => {
    const nodes = [...target.childNodes]; // ノードリストを配列にする
    let returnText = ""; // 最終的に返すテキスト

    for (const node of nodes) {
      if (node.nodeType == 3) {
        //テキストの場合
        const text = node.textContent.replace(/\r?\n/g, ""); //テキストから改行コード削除
        const splitText = text.split(""); // 一文字ずつ分割
        for (const char of splitText) {
          returnText += `<span>${char}</span>`; // spanタグで挟んで連結
        }
      } else {
        //テキスト以外の場合
        //<br>などテキスト以外の要素をそのまま連結
        returnText += node.outerHTML;
      }
    }
    return returnText;
  };

  const spanTexts = [...document.querySelectorAll(".js-text-span")];
  for (const spanText of spanTexts) {
    spanText.innerHTML = spanWrapText(spanText);
    spanText.spans = spanText.querySelectorAll("span");
  }
}
