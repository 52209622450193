"use strict";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { EaselPlugin } from "gsap/EaselPlugin";
import { DESIGN_WIDTHS, BREAKPOINTS, mediaQuery } from "../utility/_variable";
import * as modGsap from "../utility/_gsap";
import * as animation from "../modules/_animation";
import Splide from "@splidejs/splide";
modGsap.init();
animation.init();
window.addEventListener("DOMContentLoaded", () => {
  // const mm = modGsap.gsap.matchMedia();
  // mm.add(`(min-width: ${BREAKPOINTS.medium}px)`, () => {
  //   document.querySelector(".index-kv-inner__sp").remove();
  // });
  // mm.add(`(max-width: ${BREAKPOINTS.medium - 1}px)`, () => {
  //   document.querySelector(".index-kv-inner__pc").remove();
  // });
  indexKvAnimation();
  indexKvSlider();
  stepLetter();
});
function indexKvSlider() {
  const kvPrimary = ".kv-slide--primary";
  const kvSecondary = ".kv-slide--secondary";
  let kvSlide_options = {
    type: "fade",
    rewind: true,
    autoplay: true,
    arrows: false,
    interval: 4000, //1枚のスライドをどのぐらい表示させておくか（次のスライド表示まで何秒かけるか。）⇒Swiperのautoplayのdelayに相当
    speed: 1000, //次のスライダーへの移動時間をミリ秒単位で指定（ミリ秒）
  };
  let kvSlideSec_options = {
    type: "fade",
    rewind: true,
    autoplay: true,
    arrows: false,
    interval: 4000, //1枚のスライドをどのぐらい表示させておくか（次のスライド表示まで何秒かけるか。）⇒Swiperのautoplayのdelayに相当
    speed: 1000, //次のスライダーへの移動時間をミリ秒単位で指定（ミリ秒）
  };
  const kvPrimarySplide = new Splide(kvPrimary, kvSlide_options);
  const kvSecondarySplide = new Splide(kvSecondary, kvSlideSec_options);
  kvPrimarySplide.sync(kvSecondarySplide);
  kvPrimarySplide.mount();
  kvSecondarySplide.mount();
}

// 真っ白画面
// フェードで画像が見える
// テキストが表示
// 裏のウェーブが表示
function indexKvAnimation() {
  const kvPrime = document.querySelector(".kv-cover--primary");
  const kvSecondary = document.querySelector(".kv-cover--secondary");
  const kvWave = document.querySelector(".index-kv-wave");
  const kvSubLetter = document.querySelectorAll(".index-kv-inner__sub");
  const kvLetter = document.querySelectorAll(".index-kv-inner");
  const kvLetterSet = {
    transformOrigin: "100% 100%",
    rotationX: 150,
    opacity: 0,
    ease: "power2.out",
  };
  const kvLetterTo = {
    opacity: 1,
    rotationX: -95,
  };
  const kvTl = gsap.timeline();
  kvTl
    .set(kvWave, {
      opacity: 0,
      duration: 4,
      y: 20,
    })
    .set(kvLetter, {
      opacity: 0,
      y: 20,
      duration: 4,
    })
    .set(kvSubLetter, {
      opacity: 0,
      y: 10,
      duration: 4,
    })
    .to(
      kvPrime,
      {
        x: "-200%",
        duration: 4,
      },
      1
    )
    .to(
      kvSecondary,
      {
        x: "200%",
        duration: 4,
      },
      "<"
    )
    .to(
      kvLetter,
      {
        opacity: 1,
        y: 0,
      },
      2
    )
    .to(
      kvSubLetter,
      {
        opacity: 1,
        y: 0,
      },
      3
    )
    .to(
      kvWave,
      {
        opacity: 1,
        y: 0,
      },
      4
    );
  // setTimeout(() => {
  //   indexKvSlider();
  // }, 6000);
}

// kv ピン留め
ScrollTrigger.create({
  invalidateOnRefresh: true,
  trigger: ".index-kv",
  // pin: true,
  // markers: true,
  start: "top top",
  // endTrigger: ".footer",
  // pinSpacing: false,
  // end: 'bottom 30%' //などと設定するとfixedの期間がより短くなる
});

// window.addEventListener("resize", resizeWindow);
// kv裏地パララックス
const mm = modGsap.gsap.matchMedia();
let parallaxDiff = 0;
mm.add(`(min-width: ${BREAKPOINTS.medium}px)`, () => {
  parallaxDiff = 50;
});
mm.add(`(max-width: ${BREAKPOINTS.medium - 1}px)`, () => {
  parallaxDiff = 10;
});
const targets = document.querySelectorAll(".index-kv-bg__secondary");
targets.forEach((target) => {
  gsap.fromTo(
    target.querySelector(".kv-slide--secondary"),
    {
      y: 0,
    },
    {
      y: parallaxDiff,
      ease: "power1.out",
      scrollTrigger: {
        trigger: target,
        start: "top bottom",
        end: "bottom top",
        scrub: 1,
      },
    }
  );
});
function stepLetter() {
  const kvLetter = document.querySelectorAll(".step-letter");
  const kvLetterSet = {
    transformOrigin: "bottom bottom",
    rotationX: 150,
    opacity: 0,
    ease: "power2.out",
  };
  const kvLetterTo = {
    opacity: 1,
    rotationX: 95,
  };
  gsap.set(kvLetter, kvLetterSet);
  gsap.to(kvLetter, {
    ...kvLetterTo,
    stagger: {
      from: "start", //左側から
      amount: 1, // 0.8秒おきに
    },
  });
}

// function stopSplide() {
//   document.querySelectorAll(".splide").forEach((item) => {
//     const messageSec = document.querySelector(".index-main__message");
//     gsap.to(item, {
//       scrollTrigger: {
//         trigger: messageSec,
//         start: "bottom top",
//         // splide を止める
//       },
//     });
//   });
// }
// stopSplide();
